


$( document ).ready(function() {

    /*** SEARCH ***/

    /*** END SEARCH ***/


    $(".js-scroll-bar").mCustomScrollbar();

    if ($('#map').length) {
        var init = function init() {
            $(".js-contact-item2").on("click" , function(){
                myMap.panTo([46.455065, 41.544019]);
            });

            $(".js-contact-item1").on("click" , function(){
                myMap.panTo([47.138922, 39.775344]);
            });

            var myMap = new ymaps.Map("map", {
                center: [47.138922, 39.775344],
                zoom: 18
            });
            myMap.controls.add(new ymaps.control.ZoomControl());
            var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
                iconLayout: 'default#image',
                iconImageHref: 'images/common/marker.svg',
                iconImageSize: [46, 85],
                iconImageOffset: [-32, -85]
            });

            var myPlacemark2 = new ymaps.Placemark([46.455065, 41.544019], {}, {
                iconLayout: 'default#image',
                iconImageHref: 'images/common/marker.svg',
                iconImageSize: [46, 85],
                iconImageOffset: [-32, -85]
            });

            myMap.controls.remove('geolocationControl');
            myMap.controls.remove('searchControl');
            myMap.controls.remove('trafficControl');
            myMap.controls.remove('typeSelector');
            myMap.controls.remove('fullscreenControl');
            myMap.controls.remove('rulerControl');

            myMap.geoObjects.add(myPlacemark);
            myMap.geoObjects.add(myPlacemark2);
        };

        ymaps.ready(init);
    }

    $(".js-contact-item").on("click" , function(){
        $(".js-contact-item").removeClass("is-active")
        $(this).addClass("is-active");
    });

   

    /*** MOBILE MENU ***/
    $(".js-hamburger-menu").on("click" , function(){
        $(".js-mobile-menu").addClass("is-active");
    });

    $(".js-mobile-menu-close").on("click" , function(){
        $(".js-mobile-menu").removeClass("is-active");
    });

    $(".js-mobile-link").on("click" , function(){
        $(this).next(".js-mobile-dropmenu").addClass("is-active");
        return false;
    });

    $(".js-dropmenu-back").on("click" , function(){
        $(this).parents(".js-mobile-dropmenu").removeClass("is-active");
    });

    $(document).click(function (e){ 
		var div = $(".js-mobile-dropmenu"); 
		if (!div.is(e.target) 
		    && div.has(e.target).length === 0) {
			$(".js-mobile-dropmenu").removeClass("is-active");
		}
	});
    /*** END MOBILE MENU ***/


    /*** SHARE ***/
    $(".js-share-btn").on("mouseover" , function(){
        $(".js-share-body").addClass("is-active");
    });
    /*** END SHARE ***/

    $('.js-share-body')
    .mouseover(function(){         // вешаем основной обработчик на родителя
        $(".js-share-body").removeClass("is-active");
    })
    .children()
    .mouseover(function(e){        // вешаем на потомков
        e.stopPropagation();   // предотвращаем всплытие
    });

    /*** DROPMEGA ***/
    $(".js-dropmega-link").on("mouseover" , function(){
        let itemName = $(this).data("img");

        $(".js-dropmega-link").removeClass("is-active")
        $(this).addClass("is-active");

        $(".js-dropmega-img").removeClass("is-active")
        $(".js-dropmega-img[data-img="+itemName+"]").addClass("is-active");
    });
    /*** END DROPMEGA ***/



    /*** CALC ***/
        $( ".js-slider" ).slider({
            orientation: "horizontal",
            range: "min",
            max: 255
        });
    /*** END CALC ***/

    /*** TOOLTIP ***/
        $(".js-tooltip").on("click" , function(){ 
            $(".js-tooltip-item").removeClass("is-active");
            $(this).parents(".js-tooltip-item").toggleClass("is-active");
        });
    /*** END TOOLTIP ***/

    /*** POPUP ***/

    if ($(window).width() > 960) {
        $(".js-city-list-item").on("mouseover" , function(){ 
            $(".js-city-list-item").removeClass("is-active");

            $(this).addClass("is-active");
        });
    }

    if ($(window).width() < 961) {
        $(".js-city-select-top").on("click" , function(){ 
            let citySelect = $(this).parents(".js-city-select");

            citySelect.toggleClass("is-active")
            /*$(".js-city-list-item").removeClass("is-active");

            $(this).addClass("is-active");*/
        });

        $(".js-city-select-option").on("click" , function(){ 
            let nameItem = $(this).text(),
                numberItem = $(this).data("city");

            $(".js-city-select-name").text(nameItem);    
            $(".js-city-list-item").removeClass("is-active");

            $(".js-city-list-item[data-city="+numberItem+"]").addClass("is-active");
            $(".js-city-select").removeClass("is-active");
        });
    }


    $(".js-popup-open").on("click" , function(){ 
        let popup = $(this).data("popup");

        $("body").addClass("is-hidden");
        $(".js-popup[data-popup="+popup+"]").addClass("is-open");
        return false;
    });


    $(document).on("click" , function(e){ 
        var popup = $(".js-popup");
        if (popup.is(e.target)) {
            $(popup).removeClass("is-open");
            $("body").removeClass("is-hidden");
        }
        });
    

    $(".js-popup-close").on("click" , function(){ 
        $("body").removeClass("is-hidden");
        $(this).parents(".js-popup").removeClass("is-open");
    });
    /*** END POPUP ***/


    /*** CARD ***/
    if ($(window).width() < 960) {
        $(".js-card-title").on("click", function(){
            $(this).toggleClass("is-active");

            $(this).next(".js-card-body").slideToggle( "300", function() {
               
            });
        });

        
    }
   
    /*** END CARD ***/

    /*** HEADER ***/

    if ($(window).width() > 960) {
        $(".js-hamburger-main").on("click", function(){
            $(".js-header-base").removeClass("is-hidden");
            $(".js-header-fixed").removeClass("is-active");
        })

        $(window).scroll(function(){
            let st = $(this).scrollTop();

            if (st > 10) {
                $(".js-header-base").addClass("is-hidden");
                $(".js-header-fixed").addClass("is-active");
            } else {
                $(".js-header-base").removeClass("is-hidden");
                $(".js-header-fixed").removeClass("is-active");
            }

        });

     
    }

    if ($(window).width() < 961) {
        $(".js-hamburger-main").on("click", function(){
            $(".js-header-fixed").removeClass("is-hidden");
            $(".js-header-mobile").removeClass("is-active");
            $(".js-aside-info-mobile").removeClass("is-active");
        })

        $(window).scroll(function(){
            let st = $(this).scrollTop();

            if (st > 10) {
                $(".js-header-fixed").addClass("is-hidden");
                $(".js-header-mobile").addClass("is-active");
                $(".js-aside-info-mobile").addClass("is-active");
            } else {
                $(".js-header-fixed").removeClass("is-hidden");
                $(".js-header-mobile").removeClass("is-active");
                $(".js-aside-info-mobile").removeClass("is-active");
            }

        });
    }

    /*** END HEADER ***/

    /*** COMMON ***/
    $(".js-sticky").stick_in_parent({
        offset_top: 80
    }).on("sticky_kit:stick", function(e) {
        $(".js-aside-info").slideDown(300, function() { });
      }).on("sticky_kit:unstick", function(e) {
        $(".js-aside-info").slideUp(300, function() { });
      
      });

    if ($(window).width() > 960) {
        $(".js-block-aside").stick_in_parent({
            offset_top: 100
        });
    }

    $(".js-big-name").dotdotdot({
		height: 48
	});
   
    $(".js-small-name").dotdotdot({
		height: 42
    });

    $(".js-diller-item").on("mouseover" , function(){ 
        let dillerName = $(this).data("fullname"),
            posDiler = $(this).position();

        console.log("posDiler = " , posDiler);    
        $(".js-diller-item").removeClass("is-active");

        $(".js-map-title").offset({top: posDiler.top - 20, left: posDiler.left});
        $(".js-map-title").text(dillerName);
        $(".js-map-title").addClass("is-active");
    });
    
    $(".js-diler-top").on("click" , function(){ 
        let dilerItem = $(this).parents(".js-diler-info"),
            dillerName = dilerItem.data("name"),
            dillerFullName = $(".js-diller-item[data-name="+dillerName+"]").data("fullname"),
            posDiler = $(".js-diller-item[data-name="+dillerName+"]").position();

        $(".js-map-title").offset({top: posDiler.top - 20, left: posDiler.left});
        $(".js-map-title").text(dillerFullName);
        $(".js-map-title").addClass("is-active");

        dilerItem.addClass("is-active");
        $(".js-diller-item[data-name="+dillerName+"]").addClass("is-active");

        $(this).toggleClass("is-active");
        $(this).next(".js-diler-body").slideToggle(300, function(){})
    });
   
    /*** FILE ***/
    $('.js-file').on('change', function() {
        var splittedFakePath = this.value.split('\\');

        console.log("splittedFakePath =" , splittedFakePath[splittedFakePath.length - 1]);
       // $('div').text(splittedFakePath[splittedFakePath.length - 1]);

       $(".js-file-wrap").addClass("is-active");
       $(".js-file-name").text(splittedFakePath[splittedFakePath.length - 1]);
      });

      $(".js-file-close").on('click', function() {
        $(".js-file-wrap").removeClass("is-active");
        $(".js-file-name").text("");
      });
    /*** END FILE ***/

    /*** END COMMON ***/


    /*** ACCORDION ***/
    $(".js-acc-control").on("click" , function(){ 
        let parents = $(this).parents(".js-acc");

        $(this).toggleClass("is-active");

        parents.find(".js-acc-text").slideToggle(300, function(){});
    });


    $(".js-accordion-control").on("click" , function(){ 
        let parents = $(this).parents(".js-accordion");

        parents.toggleClass("is-active");

        parents.find(".js-accordion-text").slideToggle(300, function(){});
        parents.find(".js-accordion-img").slideToggle(300, function(){});
    });
    /*** END ACCORDION ***/

    /*** TAB ****/

    $(".js-tab-item").on("click" , function(){ 
        let tabNumber = $(this).data("tab"),
            parents = $(this).parents(".js-tab");

        $(parents).find(".js-tab-item").removeClass("is-active");
        $(this).addClass("is-active");

        $(parents).find(".js-tab-block").removeClass("is-active");
        $(parents).find(".js-tab-block[data-tab="+tabNumber+"]").addClass("is-active");
    });

    /***  END TAB ****/


    /*** SLIDER ***/

    $(".js-tech-slider").on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
        let i = (currentSlide ? currentSlide : 0) + 1;

        $(".js-tech-slider-current").text(i);
        $(".js-tech-slider-count").text(slick.slideCount);
    });


    $(".js-tech-slider").slick({
        variableWidth: true,
        arrows: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </button>"
    });



    $(".js-production-slider").on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
        let i = (currentSlide ? currentSlide : 0) + 1;

        $(".js-production-slider-number").text(i);
        $(".js-production-slider-count").text(slick.slideCount);
    });

    $(".js-production-slider").slick({
        variableWidth: true,
        arrows: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </button>"
    });


    $(".js-recently-slick").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow-wrap'>\
        <svg  width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow-wrap'>\
        <svg width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>"
    });

    $(".js-product-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </button>",
        responsive: [
            {
              breakpoint: 961,

                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 641,
  
                  settings: {
                      slidesToShow: 2,
                      variableWidth: true,
                      arrows: false
                  }
              }
        ]
    });

    $(".js-review-slick").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </button>",
        responsive: [
            {
              breakpoint: 961,

                settings: {
                    slidesToShow: 2,
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    });

    $(".js-slider-img").slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-wrap'>\
        <svg  width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </button></div>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-wrap'>\
        <svg width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </button></div>",
        responsive: [
            {
              breakpoint: 961,

                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    });

    $('.js-slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg width='8' height='12' viewBox='0 0 8 12' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </button>",
        asNavFor: '.js-slider-nav'
    });

    $('.js-slider-nav').slick({
        arrows: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        variableWidth: true,
        asNavFor: '.js-slider-for',
        focusOnSelect: true,
        responsive: [
            {
              breakpoint: 961,

                settings: {
                    arrows: true
                }
            }
        ]
    });

    /*** END SLIDER ***/

    /*** FORM ****/
    $(".js-phone").mask("+7 (999) 999-99-99");

    $(".js-phone-big").mask("+7 (999) 999-99-99",{placeholder:"+7 (000) 000-00-00"});


    $(".js-name").on( "keypress", function() {
        var that = this;
        setTimeout(function() {
            var res = /[^a-zA-Zа-яА-ЯїЇєЄіІёЁ ]/g.exec(that.value);
            that.value = that.value.replace(res, '');
        }, 0);
    });

    $(".js-form-label").on("click" , function(){
        $(this).parents(".js-form-field").addClass("is-active");
        $(this).parents(".js-form-field").find(".js-form-input").focus();
    });

    $(".js-form-input").on("blur" , function(){
        let valL = $(this).val().length;
        
        console.log(valL)

        if (valL == 0) {
            $(this).parents(".js-form-field").removeClass("is-active");
        }
    });
    /*** END FORM ****/


    /*** ANCHOR ***/
    $('.js-anchor-item').on("click" , function(){

        let anchor = $(this).data('anchor');  

        $('html, body').animate({           
            scrollTop:   $('.js-anchor-block[data-anchor='+anchor+']').offset().top - 30 
        }, 600);     
        return false;
    });

    $(window).scroll(function() {
        let scrollTop = $(window).scrollTop();

        $(".js-anchor-block").each(function( index , elem ) {
            let offsetItem = $(elem).offset().top,
                dataItem = $(this).data("anchor");

            if ((offsetItem - 120) < scrollTop) {
                $(".js-anchor-item").removeClass("is-active");
                $(".js-anchor-item-wrap").removeClass("is-active");
                $(".js-anchor-item[data-anchor=" + dataItem + "]").addClass("is-active");
                $(".js-anchor-item[data-anchor=" + dataItem + "]").parents(".js-anchor-item-wrap").addClass("is-active");
            }
        });
    });
    /*** END ANCHOR ***/


    /*** HEADER ***/
    $(window).scroll(function() {
        let scrollTop = $(window).scrollTop();

        if (scrollTop > 0) {
            $(".js-header").addClass("is-hidden is-fixed");
        } else {
            $(".js-header").removeClass("is-hidden is-fixed");
        }
    });

    $(document).click(function (e){ 
       /* if ($(".js-search-main").hasClass("is-active")) {
            var div = $(".js-search-main"); 
            if (!div.is(e.target) 
                && div.has(e.target).length === 0) {
                $(".js-search-main").removeClass("is-active");
            }
        }*/
		
	});

    $(".js-search-control").on("click" , function(){ 
        $(".js-search-main").addClass("is-active");
        $("body").addClass("is-hidden");
    });

    $(".js-search-main-close").on("click" , function(){ 
        $(".js-search-main").removeClass("is-active");
        $("body").removeClass("is-hidden");
    });

    $(".js-search-main-input").on("keyup" , function(){ 
        let numberInput = $(this).val().length;

        if (numberInput > 0) {
            $(".js-search-main-submit").addClass("is-active");
        } else {
            $(".js-search-main-submit").removeClass("is-active");
        }
    });

   

   /*** END HEADER ***/









});